import {
  Button,
  Divider,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  withStyles,
  TableRow,
  Typography,
  CircularProgress,
  Checkbox,
} from "@material-ui/core";
import Spacer from "../../components/spacer/Spacer";
import axios from "../../axios";
import { downloadFile } from "../../utils/Utils";
import { useHistory, useRouteMatch } from "react-router";
import { css } from "@emotion/react";
import MuiTableHead from "@material-ui/core/TableHead";
import React, { useEffect, useState } from "react";
import { Alert } from "@material-ui/lab";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

type alert = {
  alertMessage: string;
  type: "error";
} | null;

// const [loading, setLoading] = React.useState(true);
// const [color, setColor] = React.useState("#ffffff");
// let [requiredForms, setRequiredForms] = React.useState([]);

export type FileUploadProps = {
  requiredForms: any[];
  onFileUpload: (state: FileUploadState, additionalFiles: any[]) => () => void;
  type?: "download" | "upload";
  onNext?: () => void;
  onBack?: () => void;
  id: string;
  loading?: boolean;
};

export type FileUploadState = { [key: string]: File };

const FileUpload: React.FC<FileUploadProps> = ({
  requiredForms,
  onFileUpload,
  loading,
  type = "uplaod",
  onNext,
  onBack,
  id,
}) => {
  const [alert, setAlert] = React.useState<alert[]>(
    Array(requiredForms.length).fill(null)
  );
  const [reviewed, setReviewed] = React.useState<boolean[]>(
    Array(requiredForms.length).fill(false)
  );
  const [addPdfAlert, setAddPdfAlert] = React.useState<alert>(null);
  const [files, setFiles] = React.useState<FileUploadState>({});
  const [additionalFiles, setAdditionalFiles] = React.useState<any[]>([]);
  const [formDownloadStatus, setFormDownloadStatus] = React.useState<any>([]);

  React.useEffect(() => {
    const status = {};
    requiredForms?.forEach((i, ind) => {
      status[ind] = false;
    });
    setFormDownloadStatus(status);
  }, []);

  const onFileChange =
    (
      form: string,
      ind: number
    ): ((event: React.ChangeEvent<HTMLInputElement>) => void) =>
    (event) => {
      const fileAlerts = [...alert];
      if (event.target.files[0].name.endsWith(".pdf")) {
        setFiles({ ...files, [form]: event.target.files[0] });
        fileAlerts[ind] = null;
        setAlert(fileAlerts);
      } else {
        fileAlerts[ind] = {
          alertMessage: "Only PDF type files can be uploaded",
          type: "error",
        };
        setAlert(fileAlerts);
      }
    };

  const onAdditonalFilesChange = (files) => {
    const filesList = [...files];
    let pdfFileList = [];
    for (let i in filesList) {
      let file = filesList[i];
      if (file.name.endsWith(".pdf")) {
        console.log("file:", file);
        pdfFileList.push(file);
        setAddPdfAlert(null);
      } else
        setAddPdfAlert({
          alertMessage: "Only PDF type files can be uploaded.",
          type: "error",
        });
    }
    console.log("event files", filesList);
    setAdditionalFiles(pdfFileList);
  };

  const disabled =
    requiredForms.filter((form: any) => form.wetSignRequired || form.fillable)
      .length > Object.keys(files).length;
  // let loading3 =
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("access-token")}`,
  };
  const downloadSubmission = (submission, ind) => {
    console.log("Entering downloadSubmission", submission);
    // const [loading, setLoading] = React.useState(true);
    setFormDownloadStatus({ ...formDownloadStatus, [ind]: true });
    axios
      .get(`submissions/download`, {
        headers,
        params: { key: submission },
        responseType: "arraybuffer",
      })
      .then(
        downloadFile({
          type: "application/pdf",
          filename: getSubmissionKey(submission),
        })
      )
      .then(() => {
        setFormDownloadStatus({ ...formDownloadStatus, [ind]: false });
      });
  };

  const getSubmissionKey = (submission): string => submission?.split("/")[1];

  const { push } = useHistory();

  const TableHead = withStyles({
    root: {
      border: "black",
      padding: "1px",
      verticalAlign: "top",
      width: "100%",
      background: "linear-gradient(to right bottom, #223065, #FFFFFF)",
    },
  })(MuiTableHead);

  const SpacedDivider = withStyles({
    root: {
      marginTop: "40px",
    },
  })(Divider);
  return (
    <Spacer>
      {type === "download" ? (
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left" width={"25%"}>
                  Form
                </TableCell>
                <TableCell align="left" width={"55%"}>
                  Instructions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {requiredForms.map((element, ind) => (
                <TableRow key={ind}>
                  <TableCell align="left" key={element["label"] + ind}>
                    <Link
                      align={"left"}
                      component="button"
                      variant="body2"
                      onClick={async () => {
                        downloadSubmission(await element.id, ind);
                      }}
                    >
                      <Typography variant="body2">
                        {element.label}{" "}
                        {formDownloadStatus[ind] && (
                          <CircularProgress size={14} />
                        )}
                      </Typography>
                    </Link>
                  </TableCell>
                  <TableCell align="left" key={"instruction" + ind}>
                    <Typography variant="body2">
                      {element.instructions}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left" width={"30%"}>
                    Form
                  </TableCell>
                  <TableCell align="center" width={"20%"}>
                    File Name
                  </TableCell>
                  <TableCell align="center" width={"20%"}>
                    Reviewed
                  </TableCell>
                  <TableCell align="center" width={"30%"}>
                    Upload File
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {requiredForms.map((element, ind) => (
                  <TableRow key={ind}>
                    <TableCell align="left" key={element["label"] + ind}>
                      {element.label}
                    </TableCell>
                    <TableCell align="center" key={"file_name" + ind}>
                      {(element.wetSignRequired && !element.fillable) ||
                      (!element.wetSignRequired && element.fillable)
                        ? element?.id?.split("/")[1] || `${element.value}.pdf`
                        : "-"}
                    </TableCell>
                    <TableCell align="center" key={"reviewed" + ind}>
                      {(element.wetSignRequired && !element.fillable) ||
                      (!element.wetSignRequired && element.fillable) ? (
                        <Checkbox
                          id={"reviewed_checkbox" + ind}
                          color="primary"
                          checked={reviewed[ind]}
                          onChange={(e) => {
                            const rev_arr = [...reviewed];
                            rev_arr[ind] = e.target.checked;

                            setReviewed(rev_arr);
                          }}
                        />
                      ) : null}
                    </TableCell>
                    <TableCell align="center" key={"file_upload" + ind}>
                      {(element.wetSignRequired && !element.fillable) ||
                      (!element.wetSignRequired && element.fillable) ? (
                        <label htmlFor={`upload-${element.value}`}>
                          <Button
                            fullWidth
                            variant="contained"
                            component="span"
                            style={{ width: "70%" }}
                            disabled={!reviewed[ind]}
                          >
                            Upload PDF
                          </Button>
                          <input
                            hidden
                            id={`upload-${element.value}`}
                            type="file"
                            accept="application/pdf"
                            onChange={onFileChange(element.value, ind)}
                            disabled={
                              !(
                                element.wetSignRequired === false ||
                                element.fillable === false
                              )
                            }
                          />
                          <Grid>
                            {alert[ind] && (
                              <Alert
                                severity={alert[ind].type}
                                style={{
                                  backgroundColor: "#FFFFFF",
                                  color:
                                    alert[ind].type === "error"
                                      ? "#FF0000"
                                      : "rgb(184, 231, 251)",
                                }}
                              >
                                {alert[ind]?.alertMessage?.split(".").map(
                                  (text: any) =>
                                    text && (
                                      <>
                                        {" "}
                                        <p>{text + "."}</p>
                                      </>
                                    )
                                )}
                              </Alert>
                            )}
                            <Typography
                              color="primary"
                              variant="caption"
                              style={{ marginTop: "0.1px" }}
                            >
                              {files[element.value]?.name || ""}
                            </Typography>
                          </Grid>
                        </label>
                      ) : (
                        "Done"
                      )}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow style={{ borderBottom: 0 }}>
                  <>
                    <TableCell rowSpan={3} colSpan={2} align="left">
                      <Grid>
                        <br></br>
                        <br></br>
                        <Typography variant="subtitle1">
                          {
                            "Upload additional paperwork here if requested by Aptive. "
                          }
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography variant="subtitle1">
                          {
                            "To upload multiple documents hold the ctrl key (CMD key on mac) when selecting files and click open. Max size of each file is 25MB"
                          }
                        </Typography>
                      </Grid>
                    </TableCell>

                    <TableCell align="center">
                      <label htmlFor={`upload-additional-docs`}>
                        <Button
                          fullWidth
                          variant="contained"
                          component="span"
                          style={{ width: "70%" }}
                        >
                          Upload PDF
                        </Button>

                        <input
                          hidden
                          multiple
                          id={`upload-additional-docs`}
                          type="file"
                          accept="application/pdf"
                          onChange={(e) =>
                            onAdditonalFilesChange(e.target.files)
                          }
                        />
                        <Grid>
                          {addPdfAlert && (
                            <Alert
                              severity={addPdfAlert.type}
                              style={{
                                backgroundColor: "#FFFFFF",
                                color:
                                  addPdfAlert.type === "error"
                                    ? "#FF0000"
                                    : "rgb(184, 231, 251)",
                              }}
                            >
                              {addPdfAlert?.alertMessage?.split(".").map(
                                (text: any) =>
                                  text && (
                                    <>
                                      {" "}
                                      <p>{text + "."}</p>
                                    </>
                                  )
                              )}
                            </Alert>
                          )}
                          {additionalFiles &&
                            additionalFiles.map((fileObj) => (
                              <Grid alignItems="center">
                                <Typography color="primary" variant="caption">
                                  {fileObj?.name || ""}
                                </Typography>
                              </Grid>
                            ))}
                        </Grid>
                      </label>
                    </TableCell>
                  </>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <Divider />
        </>
      )}

      <>
        {type === "upload" ? (
          <Button variant="contained" onClick={onBack}>
            Back
          </Button>
        ) : (
          <Button variant="contained" onClick={() => push(`/form/${id}/start`)}>
            make edits
          </Button>
        )}
        {type === "download" ? (
          <Button
            variant="contained"
            color="primary"
            style={{ float: "right" }}
            onClick={onNext}
          >
            Next
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            style={{ float: "right" }}
            onClick={onFileUpload(files, additionalFiles)}
            disabled={
              disabled ||
              loading ||
              reviewed.some(
                (entry, ind) =>
                  entry == false &&
                  ((requiredForms[ind].wetSignRequired &&
                    !requiredForms[ind].fillable) ||
                    (!requiredForms[ind].wetSignRequired &&
                      requiredForms[ind].fillable))
              )
            }
          >
            Upload and submit{" "}
            {loading && (
              <CircularProgress
                style={{ marginLeft: "2px", color: "white" }}
                size={14}
              />
            )}
          </Button>
        )}
      </>
    </Spacer>
  );
};

export default FileUpload;
